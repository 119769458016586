import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';

import './RequestCharge.scss';
import IconBank from '../../../assets/images/icon-bank.svg';

interface IRequestCharge {
  visible: boolean;
  onCancel: () => void;
}

const DESC = [
  '- 충전은 100만 원 단위로, 최소 100만~최대 1,000만원까지 가능합니다.',
  '- 입금 계좌번호와 예금주명을 확인하여 입금 오류에 주의해주세요.',
  '- 입금자 이름은 어뮤즈케어에 가입된 병원 이름과 반드시 일치해야 합니다.',
  '- 입금 후 충전 처리는 상황에 따라 지연될 수 있습니다.',
  '- 충전 완료시, 등록된 담당자 연락처로 충전 완료 문자가 발송됩니다.',
  '- 입금 과정에서 오류 발생 시, 어뮤즈케어 파트너 카카오톡으로 문의 바랍니다.',
];

export default function RequestCharge({ visible, onCancel }: IRequestCharge) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      footer={null}
      width={425}
      bodyStyle={{ padding: '3.2rem 2rem 2rem 2rem' }}
      maskClosable={false}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <div className="RequestCharge__header">
        <img src={IconBank} alt="은행 아이콘" />
        <div className="RequestCharge__header--top">
          <h1>
            하나은행 <span style={{ fontFamily: 'Saira' }}>189-910039-78404</span>
          </h1>
          <p>예금주 주식회사어뮤즈케어</p>
        </div>
      </div>
      <div className="RequestCharge__header--mid">
        <div>충전할 금액의 10%의 부가세를 꼭 포함하여 입금해주세요</div>
        <div>예) 100만 원 충전 시, 110만 원 입금</div>
      </div>
      <div className="RequestCharge__header--bot">
        {DESC.map((value, idx) => (
          <div key={idx} style={{ lineHeight: '1.71' }}>
            {value}
          </div>
        ))}
      </div>
      <div>
        <Button type="primary" style={{ width: '100%', height: '6.4rem' }} onClick={onCancel}>
          확인
        </Button>
      </div>
    </Modal>
  );
}

import { notification } from 'antd';
import XLSX from 'xlsx';

import { Status } from 'modules/filter/filter.models';
import { IEventReservations } from 'modules/reservation/reservation.models';
import dayjs from 'dayjs';

const column = [
  '신청일시',
  '이름',
  '성별',
  '나이',
  '전화번호',
  '경로',
  '예약일정',
  '과금액',
  '상태',
  '메모1',
  '메모2',
  '메모3',
];

export function exportToExcel(
  eventReservations: IEventReservations,
  filterModel: any,
  hospital?: any
) {
  console.log('hospital', hospital.id, hospital);

  // 73,97 신현재/혈당콜에서만 메모 형식이 바뀜
  const isSsm = ['73', '97'].includes(hospital.id);
  try {
    let userRow = '';
    const reservationData = eventReservations.data.reduce(
      (acc, value) => {
        userRow = `${value.username} `;

        const sepMemo = !isSsm
          ? [value.memo]
          : value.memo
              ?.split('/')
              .filter((a) => {
                return !a.includes('나이');
              })
              .map((tt) => {
                return tt.replace(/^(몸무게|키|감량희망)./gim, '');
              }) || [];

        const memoAge = value.memo?.split('/').filter((a) => a.includes('나이'))[0] || '';

        acc.push([
          dayjs(value.createdAt).format('YYYY-MM-DD HH:mm'),
          userRow,
          value.userGender && `${value.userGender === 'male' ? ' 남' : ' 여'}`,
          value.userBirthYear
            ? `${dayjs().year() - value.userBirthYear + 1}세`
            : memoAge.replace(/나이:/gi, ''),
          value.userPhonenum,
          value.event.therapyName,
          value.reservationTime && dayjs(value.reservationTime).format('YYYY-MM-DD HH:mm'),
          value.refund ? 0 : value.chargePrice,
          Status[value.status],
          ...sepMemo,
        ]);

        return acc;
      },
      [column] as any
    );

    const worksheet = XLSX.utils.aoa_to_sheet(reservationData);
    const new_workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS');
    XLSX.writeFile(new_workbook, handleFilename(filterModel));
  } catch (error) {
    console.error('fail - export to excel', error);
    notification.error({
      message: '액셀 내보내기 실패',
      description: '문제가 지속되면 관리자에게 연락해주세요.',
      placement: 'bottomRight',
    });
  }
}

function handleFilename(model: any) {
  if (model.date) {
    const { startDate, endDate } = model.date;
    return `[예약관리] ${startDate} ~ ${endDate} ${new Date().getTime()}.xlsx`;
  }

  return `[예약관리] 전체날짜 ${new Date().getTime()}.xlsx`;
}

import { useCallback, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { MUTATION_UPDATE_RESERVATION, QUERY_GET_RESERVATIONS } from 'query/reservation.query';
import { setFetching, setReservations } from 'modules/reservation/reservation.actions';
import { IResponse } from 'modules/reservation/reservation.models';
import { RootState } from 'modules/rootState';
import { UpdateReservationMutation } from 'generated/graphql';
import { exportToExcel } from 'utils/exportToExcel';

export default function useReservation() {
  const dispatch = useDispatch();
  const [visibleNotEnough, setVisibleNotEnough] = useState(false);
  const [visibleDisabledHospital, setDisabledHospital] = useState(false);
  const { data, isFetching, pagination } = useSelector((state: RootState) => state.reservation);
  const { reservationFilter } = useSelector((state: RootState) => state.filter);
  const { status, path, range, name, phone } = reservationFilter;
  const { auth } = useSelector((state: RootState) => state);

  const filterModel = Object.assign(
    {},
    status !== 'A01' && { status },
    path !== 'All' && { path },
    name.trim() !== '' && { username: name },
    phone.trim() !== '' && { userPhonenum: phone },
    range.every((v: any) => v !== null) && {
      date: {
        startDate: range[0],
        endDate: range[1],
      },
    }
  );

  const [getReservations, { loading }] = useLazyQuery<IResponse>(QUERY_GET_RESERVATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => dispatch(setReservations(res.eventReservations)),
    onError: (err) => {
      // 부채가 너모 많음 (-50,000)
      if (err.message === 'POINT_NOT_ENOUGH') {
        setVisibleNotEnough(true);
      } else if (err.message === 'INACTIVE_HOSPITAL') {
        setDisabledHospital(true);
      }
    },
  });

  const [getReservationsToExcel] = useLazyQuery<IResponse>(QUERY_GET_RESERVATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ eventReservations }) =>
      exportToExcel(eventReservations, filterModel, auth.hospital),
    onError: (err) => console.error('엑셀안댐', err),
  });

  const [updateRequest, { loading: fetchingUpdate }] = useMutation<UpdateReservationMutation>(
    MUTATION_UPDATE_RESERVATION,
    {
      onCompleted: (res) =>
        getReservations({
          variables: { filter: filterModel, pagination: { currentPage: pagination.currentPage } },
        }),
      onError: (error) => console.log('UpdateReservationMutation', error),
    }
  );

  useEffect(() => {
    dispatch(setFetching(true));
    getReservations();
  }, [dispatch, getReservations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const updateReservation = useCallback(
    (option) => {
      dispatch(setFetching(true));
      updateRequest(option);
    },
    [dispatch, updateRequest]
  );

  return {
    dataSource: data,
    isFetching: isFetching || loading || fetchingUpdate,
    filterModel,
    pagination,
    visibleNotEnough,
    visibleDisabledHospital,
    updateReservation,
    getReservations,
    getReservationsToExcel,
  };
}

import React from 'react';
import { Alert } from 'antd';

export default function BrowserAlert() {
  return (
    <Alert
      message="어뮤즈케어 파트너페이지는 Chrome, Safari, Firefox등 모던 브라우저에 최적화되어 있습니다."
      type="warning"
      banner
      closable
      style={{ position: 'fixed', top: '6rem', width: '100%' }}
    />
  );
}
